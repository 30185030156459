import React, { useState, useRef } from 'react';
import { Analytics } from "@vercel/analytics/react"

const SunaldLandingPage = () => {
  const [copied, setCopied] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const tokenomicsRef = useRef(null);
  const contractAddress = 'TSU9u7KeND3YyLALy8cgXLDpNXKJorKzHx';

  const copyToClipboard = () => {
    navigator.clipboard.writeText(contractAddress).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const scrollToTokenomics = () => {
    tokenomicsRef.current?.scrollIntoView({ behavior: 'smooth' });
    setMobileMenuOpen(false);
  };

  const tokenomicsData = [
    { number: '01', content: '100 BILLION TOKENS' },
    { number: '02', content: 'MINT REVOKED' },
    { number: '03', content: 'FUTURE BURNS' }
  ];

  const socialLinks = {
    telegram: 'https://t.me/SunaldTrump',
    twitter: 'https://x.com/SunaldTrump',
    buyNow: 'https://sun.io'
  };

  return (
    <div className="font-poppins">
      <div className="min-h-screen bg-cover bg-center flex flex-col landing-bg">
        {/* Navigation */}
        <nav className="p-4 flex justify-between items-center">
          <div className="flex items-center">
            <img src="/sunald-logo.jpeg" alt="$SUNALD Logo" className="w-12 h-12 mr-2 rounded-full" />
            <span className="font-passion-one text-3xl text-white hidden md:inline">$SUNALD</span>
          </div>
          <div className="md:space-x-6 hidden md:block">
            <button onClick={scrollToTokenomics} className="text-white font-passion-one text-xl cursor-pointer">TOKENOMICS</button>
            <a href={socialLinks.telegram} className="text-white font-passion-one text-xl">TELEGRAM</a>
            <a href={socialLinks.twitter} className="text-white font-passion-one text-xl">TWITTER</a>
            <a href={socialLinks.buyNow} className="bg-sunald-yellow text-black font-passion-one text-xl px-6 py-3 rounded-full">BUY NOW</a>
          </div>
          <button className="md:hidden text-white" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </nav>

        {/* Mobile Menu */}
        {mobileMenuOpen && (
          <div className="md:hidden absolute top-16 right-0 bg-black bg-opacity-90 w-full z-50">
            <div className="flex flex-col items-center py-4">
              <button onClick={scrollToTokenomics} className="text-white font-passion-one text-xl py-2">TOKENOMICS</button>
              <a href={socialLinks.telegram} className="text-white font-passion-one text-xl py-2">TELEGRAM</a>
              <a href={socialLinks.twitter} className="text-white font-passion-one text-xl py-2">TWITTER</a>
              <a href={socialLinks.buyNow} className="bg-sunald-yellow text-black font-passion-one text-xl px-6 py-3 rounded-full mt-2">BUY NOW</a>
            </div>
          </div>
        )}

        {/* Main Content */}
        <main className="flex-grow flex flex-col justify-between items-center px-4 py-16">
          <h1 className="font-passion-one text-5xl md:text-7xl mb-4 text-center" style={{ marginTop: '-75px' }}>
            <span className="text-sunald-yellow animate-crook">$SUNALD ON TRON</span>
          </h1>
          
          <div className="relative w-full max-w-3xl animate-pulse-scale" style={{ marginTop: 'auto', marginBottom: '2rem' }}>
            <div className="w-full bg-transparent rounded-full py-4 px-6 shadow-lg border-4 border-sunald-yellow flex items-center justify-between">
              <div 
                className="flex-grow bg-transparent text-sunald-yellow font-passion-one text-lg md:text-3xl text-center cursor-pointer overflow-x-auto whitespace-nowrap"
                onClick={copyToClipboard}
              >
                CA: {contractAddress}
              </div>
              <button 
                onClick={copyToClipboard} 
                className="ml-4 text-white font-passion-one bg-sunald-yellow px-4 py-2 rounded-full hover:bg-red-600 transition-colors flex-shrink-0 text-sm md:text-base"
              >
                {copied ? 'Copied!' : 'Copy'}
              </button>
            </div>
          </div>
        </main>
      </div>

      {/* Red Separator with Gradient */}
      <div className="h-8 bg-gradient-to-b from-sunald-red to-sunald-dark-red"></div>

      {/* Dance Floor Section */}
      <div className="min-h-screen relative overflow-hidden dance-floor-section">
        {/* TOKENOMICS text */}
        <div ref={tokenomicsRef} className="absolute top-24 left-10 md:left-56 z-10">
          <h2 className="text-sunald-yellow text-5xl md:text-7xl font-bold font-passion-one">TOKENOMICS</h2>
        </div>

        <div className="absolute inset-x-0 bottom-0 flex justify-center md:w-3/4 w-full">
          <img 
            src="/dancing.png" 
            alt="Dancing" 
            className="animate-dance object-contain relative z-10 md:max-h-[130vh] max-h-[65vh]"
            style={{
              maxWidth: '100%',
            }}
          />
        </div>

        {/* Tokenomics Cards */}
        <div className="absolute top-1/2 right-1/4 transform -translate-y-1/2 translate-x-1/4 w-[28rem] space-y-10 md:block hidden">
          {tokenomicsData.map((item, index) => (
            <div 
              key={index} 
              className={`w-full overflow-hidden ${
                index % 2 === 0 ? '-ml-10' : 'ml-10'
              }`}
            >
              <div className="h-full w-full flex items-center">
                <div className="flex-1 pr-8">
                  <div className="bg-transparent border-4 border-sunald-yellow rounded-full inline-block px-8 py-4 pr-12">
                    <div className="text-sunald-yellow text-5xl font-bold mb-1 font-passion-one">{item.number}</div>
                    <h2 className="text-sunald-yellow text-2xl font-bold font-passion-one">{item.content}</h2>
                  </div>
                </div>
                <img 
                  src={index === 0 ? "/money.gif" : index === 1 ? "/mint.gif" : "/burning.gif"} 
                  alt={`GIF ${index + 1}`}
                  className={`w-36 h-36 object-cover rounded-lg ${index === 0 ? 'animate-penguin' : ''}`}
                />
              </div>
            </div>
          ))}
        </div>

        {/* Mobile Tokenomics */}
        <div className="md:hidden absolute top-40 left-0 w-full flex flex-col justify-center items-center z-20 px-4">
          {tokenomicsData.map((item, index) => (
            <div key={index} className="bg-black bg-opacity-70 rounded-lg p-4 mb-4 w-full max-w-xs">
              <div className="text-sunald-yellow text-3xl font-bold mb-1 font-passion-one">{item.number}</div>
              <h3 className="text-sunald-yellow text-xl font-bold font-passion-one">{item.content}</h3>
            </div>
          ))}
        </div>

        {/* Trademark */}
        <div className="absolute bottom-4 right-4 text-white font-passion-one text-sm">
          ™ Sunald 2024
        </div>
      </div>

      <style jsx>{`
        .landing-bg {
          background-image: url('/S3.png');
          background-size: cover;
          background-position: center;
        }

        @media (max-width: 768px) {
          .landing-bg {
            background-size: 250% 100%;
            background-position: 17% 90%;
          }
        }

        @keyframes pulse-scale {
          0%, 100% { transform: scale(1); }
          50% { transform: scale(0.95); }
        }
        .animate-pulse-scale {
          animation: pulse-scale 3s ease-in-out infinite;
        }

        @keyframes penguin-animation {
          0% { opacity: 0.99; }
          100% { opacity: 1; }
        }
        .animate-penguin {
          animation: penguin-animation 0.01s infinite;
        }

        @keyframes crook {
          0%, 75%, 100% { transform: rotate(0deg); }
          80% { transform: rotate(-7deg); }
          85% { transform: rotate(7deg); }
          90% { transform: rotate(-7deg); }
          95% { transform: rotate(7deg); }
        }
        .animate-crook {
          display: inline-block;
          animation: crook 2.1s ease-in-out infinite;
        }

        @keyframes dance {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-20px); }
        }
        .animate-dance {
          animation: dance 1.5s ease-in-out infinite;
        }
        .dance-floor-section::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: radial-gradient(circle, #FF0000 0%, #4B0000 100%);
          z-index: 0;
        }

        @media (max-width: 768px) {
          .dance-floor-section::before {
            background: linear-gradient(to bottom, #FF0000 0%, #4B0000 100%);
          }
        }
      `}</style>
      <Analytics />
    </div>
  );
};

export default SunaldLandingPage;
